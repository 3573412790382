export default defineNuxtRouteMiddleware((to) => {
  const routeName = to.name?.toString();
  const isClient = import.meta.client; // True when evaluated on the client side.

  if (!routeName) return;

  const analyticsStore = useAnalyticsStore();

  if (isClient && !['yandex', 'vk'].includes(routeName)) {
    analyticsStore.openPage(routeName);
  }
});

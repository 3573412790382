import { useAuthStore } from '~/stores/AuthStore';
import { useUserStore } from '~/stores/UserStore';

export default defineNuxtRouteMiddleware(async (to) => {
  const userStore = useUserStore();
  const authStore = useAuthStore();

  const token = to.query.token;
  if (!authStore.token && token?.length) {
    await authStore.loginByToken(token as string);
    return navigateTo({ ...to, query: {} }, { replace: true });
  }

  if (authStore.token && !userStore.user) await userStore.fetchUser();

  // 'projects-id',
  if (!['topics', 'topics-slug', 'projects-id', 'index', 'yandex', 'vk', 'entity'].includes(<string>to.name)) {
    if (!userStore.user) {
      return navigateTo('/');
    }
  }

  return;
});

import type { FetchError } from 'ofetch';
import { defineStore } from 'pinia';
import { login as loginApi, loginByVK as loginByVKApi, loginByYandex as loginByYandexApi, register as registerApi } from '~/api/auth';

export enum AuthStatus {
  UNAUTHORIZED,
  AUTHORIZED,
}

export const useAuthStore = defineStore('auth', () => {
  const userStore = useUserStore();
  const token = useCookie<string | null>('token', { maxAge: 86400 * 365, default: () => null });
  const email = ref('');
  const password = ref('');
  const name = ref('');
  const passwordConfirmation = ref('');
  const authError = ref('');
  const status = ref<AuthStatus>(token.value ? AuthStatus.AUTHORIZED : AuthStatus.UNAUTHORIZED);

  const showAuth = ref(false);
  const showRegister = ref(false);

  const login = async () => {
    await loginApi(email.value, password.value)
      .then(async (response) => {
        await loginByToken(response.token);
      })
      .catch((e: FetchError) => {
        authError.value = e.response?._data?.message || 'Произошла ошибка';
      });
  };

  const loginByYandex = async (token: string) => {
    await loginByYandexApi(token)
      .then(async (response) => {
        await loginByToken(response.token);
      })
      .catch((e: FetchError) => {
        authError.value = e.response?._data?.message || 'Произошла ошибка';
      });
  };

  const loginByVK = async (payload: string) => {
    await loginByVKApi(payload)
      .then(async (response) => {
        await loginByToken(response.token);
      })
      .catch((e: FetchError) => {
        authError.value = e.response?._data?.message || 'Произошла ошибка';
      });
  };

  const loginByToken = async (tokenValue: string) => {
    token.value = tokenValue;
    status.value = AuthStatus.AUTHORIZED;
    await userStore.fetchUser();
  };

  const register = async () => {
    await registerApi(name.value, email.value, password.value, password.value)
      .then(async (response) => {
        await loginByToken(response.token);
      })
      .catch((e: FetchError) => {
        authError.value = e.response?._data?.message || 'Произошла ошибка';
      });
  };

  const logout = () => {
    token.value = null;
    password.value = '';
    email.value = '';
    status.value = AuthStatus.UNAUTHORIZED;
    userStore.user = undefined;
  };

  return {
    login,
    register,
    email,
    name,
    passwordConfirmation,
    password,
    status,
    authError,
    logout,
    loginByYandex,
    loginByVK,
    loginByToken,
    showAuth,
    showRegister,
    token,
  };
});

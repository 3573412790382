export const getApiEndpoint = () => useRuntimeConfig().public.apiEndpoint;

export type AuthResponse = {
  token: string;
};
export const login = async (email: string, password: string): Promise<AuthResponse> => {
  return await $fetch(getApiEndpoint() + 'login', {
    method: 'POST',
    body: {
      email,
      password,
    },
  });
};

export const loginByYandex = async (token: string): Promise<AuthResponse> => {
  return await $fetch(getApiEndpoint() + 'login_yandex', {
    method: 'POST',
    body: { token },
  });
};
export const loginByVK = async (payload: string): Promise<AuthResponse> => {
  return await $fetch(getApiEndpoint() + 'login_vk', {
    method: 'POST',
    body: { payload },
  });
};

export const register = async (name: string, email: string, password: string, password_confirmation: string): Promise<AuthResponse> => {
  return await $fetch(getApiEndpoint() + 'register', {
    method: 'POST',
    body: {
      name,
      email,
      password,
      password_confirmation,
    },
  });
};

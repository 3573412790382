<script setup lang="ts">
import { iconColors } from 'assets/js/utils';
import { Eye, EyeOff } from 'lucide-vue-next';
import VkAuthButton from '~/components/auth/VkAuthButton.vue';
import YandexAuthButton from '~/components/auth/YandexAuthButton.vue';
import VDivider from '~/components/VDivider.vue';
import { useAuthStore } from '~/stores/AuthStore';
import VButton from '~/components/VButton.vue';
import VInput from '~/components/VInput.vue';

interface AuthProps {
  isRegister?: boolean;
  inputSizeSmall?: boolean;
}

const props = withDefaults(defineProps<AuthProps>(), {
  isRegister: false,
  inputSizeSmall: true,
});
const showPassword = ref<boolean>(false);
const showRegister = ref<boolean>(props.isRegister);
const authStore = useAuthStore();
const emit = defineEmits(['closeAuth']);

async function register() {
  await authStore.register();
  if (authStore.authToken) {
    authStore.authError = '';
  }
  if (!authStore.authError) {
    emit('closeAuth');
  }
}

async function login() {
  await authStore.login();
  if (authStore.authToken) {
    authStore.authError = '';
  }
  if (!authStore.authError) {
    emit('closeAuth');
  }
}

function clearAll() {
  authStore.authError = '';
  authStore.name = '';
  authStore.email = '';
  authStore.password = '';
}
</script>

<template>
  <form
    v-if="showRegister"
    class="auth"
    @keydown.enter.prevent="register()"
    @submit.prevent="register()"
  >
    <div class="auth__header">
      <NuxtImg
        src="/images/new/logo.svg"
        alt=""
        width="32"
        height="32"
      />
      <h3>Регистрация аккаунта</h3>
    </div>
    <div class="auth__actions">
      <v-input
        v-model="authStore.name"
        required
        type="text"
        autocomplete="name"
        autofocus
        placeholder="Имя"
        medium
      />
      <v-input
        v-model="authStore.email"
        required
        type="email"
        autocomplete="new-email"
        autofocus
        placeholder="Почта"
        medium
      />
      <v-input
        v-model="authStore.password"
        required
        autocomplete="new-password"
        :type="showPassword ? 'text' : 'password'"
        placeholder="Пароль"
        medium
      >
        <template #iconRight>
          <Eye
            v-if="showPassword"
            :color="iconColors.primary"
            :size="20"
            class="cursor-pointer"
            @click="showPassword = !showPassword"
          />
          <EyeOff
            v-else
            :color="iconColors.primary"
            :size="20"
            class="cursor-pointer"
            @click="showPassword = !showPassword"
          />
        </template>
      </v-input>
      <div
        v-if="authStore.authError"
        class="error mb-3"
      >
        {{ authStore.authError }}
      </div>
    </div>
    <v-button
      medium
      type="submit"
      :disabled="!authStore.name || !authStore.email || !authStore.password"
      style="width: 100%"
    >
      Зарегистрироваться
    </v-button>

    <p
      class="auth__agreement"
      style="margin: -10px 0 0; text-wrap: balance"
    >
      Нажимая «Зарегистрироваться» вы соглашаетесь на обработку персональных данных. Согласно Федеральному закону от 27 июля 2006 г. №
      152-ФЗ О персональных данных.
    </p>
    <div class="auth__footer">
      Уже есть аккаунт?
      <v-button
        schema="text"
        type="button"
        @click="
          showRegister = false;
          clearAll();
        "
      >
        Войти
      </v-button>
    </div>
  </form>
  <form
    v-else
    class="auth"
    @keydown.enter.prevent="login()"
    @submit.prevent="login()"
  >
    <div class="auth__header">
      <NuxtImg
        src="/images/new/logo.svg"
        alt=""
        width="32"
        height="32"
      />
      <h3>Вход в аккаунт</h3>
    </div>
    <div class="auth__buttons-id">
      <YandexAuthButton @close-auth="$emit('closeAuth')" />
      <VkAuthButton />
    </div>
    <v-divider
      text
      is-gray
    />
    <div class="auth__actions">
      <v-input
        v-model="authStore.email"
        required
        type="email"
        autofocus
        placeholder="Почта"
      />

      <v-input
        v-model="authStore.password"
        required
        autocomplete="on"
        :type="showPassword ? 'text' : 'password'"
        placeholder="Пароль"
      >
        <template #iconRight>
          <Eye
            v-if="showPassword"
            :color="iconColors.primary"
            :size="20"
            class="cursor-pointer"
            @click="showPassword = !showPassword"
          />
          <EyeOff
            v-else
            :color="iconColors.primary"
            :size="20"
            class="cursor-pointer"
            @click="showPassword = !showPassword"
          />
        </template>
      </v-input>
      <div
        v-if="authStore.authError"
        class="error mb-3"
      >
        {{ authStore.authError }}
      </div>
      <v-button
        type="submit"
        medium
      >
        Войти
      </v-button>
    </div>
    <div class="auth__footer">
      Нет аккаунта?
      <v-button
        schema="text"
        type="button"
        @click="
          showRegister = true;
          clearAll();
        "
      >
        Зарегистрироваться
      </v-button>
    </div>
  </form>
</template>

<style scoped lang="scss">
.auth {
  display: flex;
  flex-direction: column;
  gap: 24px;
  align-items: center;

  .auth__header {
    text-align: center;

    img,
    h3 {
      margin-bottom: 12px;
    }

    h3 {
      font-size: 20px;
      font-weight: 700;
      line-height: 24px;
    }
  }

  .auth__buttons-id {
    display: flex;
    flex-direction: column;
    gap: 12px;
    width: 100%;
  }

  .auth__actions {
    display: flex;
    flex-direction: column;
    gap: 12px;
    width: 100%;

    .new-button {
      width: 100%;
    }
  }

  .auth__agreement {
    text-align: center;
    color: $foreground-gray;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
  }

  .auth__footer {
    font-size: 14px;
    line-height: 20px;
    color: #9691f0;
  }

  p {
    font-size: 16px;
    margin-bottom: 22px;
    text-align: center;
  }

  .error {
    color: $text-status-destructive;
  }

  .password-label {
    position: relative;

    input {
      width: 100%;
    }
  }

  label {
    margin-bottom: 12px;
    width: 100%;

    input {
      background: white;
      padding: 17px 20px;
      border-radius: 10px;
      border: 1px solid $foreground-theme;
      width: 100%;

      &::placeholder {
        color: $foreground-theme;
      }

      &:focus {
        outline: 2px solid $foreground-theme;
      }
    }
  }

  small {
    margin-top: 22px;
    font-size: 13px;
    line-height: 130%;
    text-align: center;
    display: block;

    a {
      text-decoration: underline;
    }
  }
}
</style>

import debounce from 'lodash.debounce';
import { defineStore } from 'pinia';
import { getApiEndpoint } from '~/api/auth';
import { authFetch } from '~/api/utils';

type Event = {
  event_name: string;
  query?: string;
  project_id?: number;
  ts?: number;
  amount?: number;
  screen?: string;
  services?: string[];
};

export const useAnalyticsStore = defineStore('analytics', () => {
  const events = ref<Event[]>([]);
  const hasSentEvents = ref<boolean>(false);

  const sendEvents = () => {
    if (hasSentEvents.value) return;

    const eventsSnapshot = [...events.value];
    events.value = [];

    hasSentEvents.value = true;
    authFetch(getApiEndpoint() + 'logEvent', {
      method: 'POST',
      body: {
        events: eventsSnapshot,
      },
    }).finally(() => {
      hasSentEvents.value = false;
    });
  };

  const sendEventsDebounced = debounce(sendEvents, 10000, { leading: true });
  const addEvent = (event: Event) => {
    event.ts = new Date().getTime();
    console.log('addEvent', event.event_name);
    events.value = [...events.value, event];
    sendEventsDebounced();
  };

  const openPage = (screen: string) => {
    return addEvent({
      event_name: 'openPage',
      screen,
    });
  };

  const openProjectPage = (projectId: number) => {
    return addEvent({
      event_name: 'openProjectPage',
      project_id: projectId,
    });
  };

  const clickActionFloatBlock = (projectId: number) => {
    return addEvent({
      event_name: 'clickActionFloatBlock',
      project_id: projectId,
    });
  };

  const toggleServices = (projectId: number, services: string[]) => {
    return addEvent({
      event_name: 'toggleServices',
      project_id: projectId,
      services,
    });
  };
  const enableService = (projectId: number, service: string) => {
    return addEvent({
      event_name: 'enableService',
      project_id: projectId,
      services: [service],
    });
  };

  const disableService = (projectId: number, service: string) => {
    return addEvent({
      event_name: 'disableService',
      project_id: projectId,
      services: [service],
    });
  };

  const enterPrompt = (query: string) => {
    return addEvent({
      event_name: 'enterPrompt',
      query,
    });
  };

  const submitPrompt = (query: string) => {
    return addEvent({
      event_name: 'submitPrompt',
      query,
    });
  };

  const submitPayForm = (projectId: number, amount: number, services: string[]) => {
    return addEvent({
      event_name: 'submitPayForm',
      project_id: projectId,
      amount,
      services,
    });
  };

  const downloadText = (projectId: number) => {
    return addEvent({
      event_name: 'downloadText',
      project_id: projectId,
    });
  };

  const downloadSlides = (projectId: number) => {
    return addEvent({
      event_name: 'downloadSlides',
      project_id: projectId,
    });
  };

  const openServiceDescription = (service: string, projectId: number) => {
    return addEvent({
      event_name: 'openServiceDescription',
      project_id: projectId,
      services: [service],
    });
  };

  const hasSeenReviews = () => {
    return addEvent({
      event_name: 'hasSeenReviews',
    });
  };

  const telegramPopupShow = () => {
    return addEvent({
      event_name: 'telegramPopupShow',
    });
  };

  const telegramPopupClose = () => {
    return addEvent({
      event_name: 'telegramPopupClose',
    });
  };

  const telegramPopupSubscribe = () => {
    return addEvent({
      event_name: 'telegramPopupSubscribe',
    });
  };

  return {
    events,
    addEvent,
    openPage,
    hasSeenReviews,
    openProjectPage,
    clickActionFloatBlock,
    toggleServices,
    enterPrompt,
    submitPrompt,
    submitPayForm,
    enableService,
    disableService,
    downloadText,
    downloadSlides,
    openServiceDescription,
    telegramPopupShow,
    telegramPopupClose,
    telegramPopupSubscribe,
  };
});

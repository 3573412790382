<script setup lang="ts">
import VueBottomSheet from '@webzlodimir/vue-bottom-sheet';
import '@webzlodimir/vue-bottom-sheet/dist/style.css';
import VAuth from '~/components/auth/VAuth.vue';

interface Props {
  isOpen: boolean;
}

const props = withDefaults(defineProps<Props>(), {
  isOpen: false,
});

const myBottomSheet = ref<InstanceType<typeof VueBottomSheet>>();
const open = () => {
  myBottomSheet.value.open();
};

const close = () => {
  myBottomSheet.value.close();
};

watch(
  () => props.isOpen,
  (val) => {
    if (val) {
      open();
    } else {
      close();
    }
  }
);
defineEmits(['closedAuthBottomSheet']);
</script>

<template>
  <client-only>
    <vue-bottom-sheet
      ref="myBottomSheet"
      @closed="$emit('closedAuthBottomSheet')"
    >
      <div class="auth-bottom-sheet">
        <v-auth
          v-if="isOpen"
          :input-size-small="false"
          @close-auth="close"
        />
      </div>
    </vue-bottom-sheet>
  </client-only>
</template>

<style lang="scss">
@import '@/assets/base/scroll';

.auth-bottom-sheet {
  padding: 30px 2px 30px 10px;
}

.bottom-sheet__content {
  height: auto !important;
}

.bottom-sheet__main {
  padding: 0 !important;
}
.bottom-sheet__draggable-thumb {
  background: #d9d9d9 !important;
  width: 48px !important;
  height: 3px !important;
}
</style>

<script setup lang="ts">
interface InputProps {
  schema?: 'primary' | 'white';
  type?: 'text' | 'password' | 'number' | 'email' | 'tel';
  modelValue?: number | string;
  id?: string;
  label?: string;
  placeholder?: string;
  disabled?: boolean;
  required?: boolean;
  autofocus?: boolean;
  lazy?: boolean;
  buttonRight?: boolean;
  iconClassLeft?: string;
  iconHtmlLeft?: string;
  iconClassRight?: string;
  step?: number;
  min?: number;
  max?: number;
  small?: boolean;
}

withDefaults(defineProps<InputProps>(), {
  schema: 'primary',
  type: 'text',
  modelValue: undefined,
  id: undefined,
  label: undefined,
  placeholder: undefined,
  disabled: false,
  required: false,
  autofocus: false,
  lazy: false,
  buttonRight: false,
  iconClassLeft: undefined,
  iconHtmlLeft: undefined,
  iconClassRight: undefined,
  step: undefined,
  min: undefined,
  max: undefined,
  small: false,
});

const inputEl = ref<HTMLInputElement>();
const isFocus = ref(false);

const emit = defineEmits<{
  (e: 'update:modelValue', v: number | string | null | undefined): void;
}>();

function onInput(e: Event) {
  emit('update:modelValue', (e.target as HTMLInputElement).value);
}
</script>

<template>
  <div
    class="new-input"
    :class="[
      {
        'new-input_disabled': disabled,
        'new-input_small': small,
      },
      schema,
    ]"
  >
    <label
      v-if="label"
      class="new-input__label"
      :for="id"
    >
      {{ label }}
    </label>
    <div :class="buttonRight ? 'new-input__group' : ''">
      <div
        class="new-input__wrap"
        @click="inputEl.focus()"
      >
        <div
          v-if="iconHtmlLeft"
          v-html="iconHtmlLeft"
        />
        <span
          v-if="iconClassLeft"
          class="new-input__icon-left"
        >
          <i
            class="icon"
            :class="iconClassLeft"
          />
        </span>
        <slot name="iconLeft" />
        <input
          :id="id"
          ref="inputEl"
          :value="modelValue"
          class="new-input__input"
          :placeholder="placeholder"
          :type="type"
          :step="step"
          :disabled="disabled"
          :required="required"
          :min="min"
          :max="max"
          :autofocus="autofocus"
          @input="onInput"
          @focus="isFocus = true"
          @blur="isFocus = false"
        />
        <slot name="iconRight" />
        <span
          v-if="iconClassRight"
          class="new-input__icon-right"
        >
          <i
            class="icon"
            :class="iconClassRight"
          />
        </span>
      </div>
      <slot />
    </div>
  </div>
</template>

<style lang="scss">
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px $background-theme-fade inset !important;
}
.new-input {
  &.white {
    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    input:-webkit-autofill:active {
      -webkit-box-shadow: 0 0 0 30px white inset !important;
    }

    .new-input__wrap {
      background: white;

      .new-input__input {
        background: white;
      }
    }
  }

  &.new-input_disabled {
    cursor: not-allowed;
    opacity: 0.4;

    .new-input__wrap {
      cursor: not-allowed;
    }

    .new-input__label {
      cursor: not-allowed;
    }
  }

  .new-input__group {
    display: flex;

    .new-input__wrap {
      width: 100%;
      border-radius: 12px 0 0 12px;
      border-right: none;
    }
  }

  &.new-input_small {
    .new-input__wrap {
      padding: 10px 12px;
      height: 40px;
    }

    .new-input__label {
      font-size: 14px;
      line-height: 20px;
      margin-bottom: 8px;
    }

    .new-input__input {
      font-size: 14px;
      line-height: 20px;
    }
  }

  .new-input__wrap {
    background: $background-theme-fade;
    cursor: text;
    padding: 12px 16px;
    border-radius: 12px;
    display: flex;
    align-items: center;
    gap: 10px;
    height: 56px;
    @include media-breakpoint-down(sm) {
      padding: 10px 12px;
      height: auto;
    }
  }

  .new-input__label {
    cursor: text;
    color: $foreground-contrast;
    font-size: 16px;
    font-weight: 600;
    line-height: 28px;
    margin-bottom: 4px;
    @include media-breakpoint-down(sm) {
      font-size: 14px;
      line-height: 20px;
      margin-bottom: 8px;
    }
  }

  .new-input__input {
    color: $foreground-contrast;
    background: $background-theme-fade;
    font-size: 18px;
    font-weight: 500;
    line-height: 28px;
    border: none;
    outline: none !important;
    appearance: none;
    //height: 100%;
    width: 100%;
    padding: 0;
    @include media-breakpoint-down(sm) {
      font-size: 16px;
    }

    &::placeholder {
      font-weight: 500;
      color: rgba($foreground-contrast, 0.4);
    }
  }

  .new-input__input[disabled] {
    pointer-events: none;
  }

  .new-input__icon-left,
  .new-input__icon-right {
    font-size: 20px;
  }

  .new-input__icon-left {
    color: $icon-theme;
  }

  .new-input__icon-right {
    color: $icon-theme-fade;
  }
}
</style>
